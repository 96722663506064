import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {intersection} from "lodash/array";
import {logout, setCredentials} from "../../features/auth-slice";
import {LANGUAGE} from "../../../data/constant/Constants";

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, {getState}) => {
        const token = getState().AuthReducer?.token;
        if (token) headers.set('authorization', `Bearer ${token}`)
        headers.set(['userSignature'], 'f0e00f86f5dfbbc90cf36b8f3b7ed272f3aa76dc3eaa9bcc24114e3c39457495');
        headers.set('X-Locale', LANGUAGE[1])
        return headers
    },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (intersection([result?.error?.originalStatus, result?.error?.status], [401, 410]).length > 0) {
        api.dispatch(logout());
        return result;
    }

    if (result?.meta?.response?.headers?.get('token')) {
        api.dispatch(setCredentials({ data: { token: result?.meta?.response?.headers?.get('token') } }));
    }

    // if (result?.data?.data?.token) {
    //
    //     console.log("result?.data -->", result?.data);
    //
    //     const validationArgs = {
    //         url: `/company/${result?.data?.data?.user?.companyId}`, // Pass companyId as a query parameter
    //         method: 'GET', // Specify the GET method
    //     };
    //
    //     const validationResult = await baseQuery(validationArgs, api, extraOptions);
    //
    //     console.log("validationResult -->", validationResult);
    //
    //     if (!validationResult?.data?.isAvailable) {
    //         // Si la compañía no está disponible, cerrar sesión y mostrar mensaje
    //         api.dispatch(logout());
    //         return { error: { status: 403, data: 'Company not available' } };
    //     }
    // }

    return result;
}
