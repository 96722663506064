import {useContext} from 'react';
import {AppContext} from "../../store/context/AppContextProvider";

const useAppContext = () => {
   const appContext = useContext(AppContext);
   const {
      videoRef,
      streamVideo,
      initStreamVideo,
      openModal,
      openAlert,
      isActiveAlert,
      errorAlert,
      messageAlert,
      severityAlert,
      timeOffAlert,
      isLoadingUploadVideo,
      enableSpeech,
      enableInitialSpeech,
      isVisibleSendCode,
      initTimerResendCode,
      timerSecondsResendCode,
      companyValues,
      onEnableSpeech,
      setStreamVideoState,
      setInitStreamVideoState,
      setOpenModalState,
      onSetOpenAlert,
      onSetIsLoadingUploadVideo,
      onEnableInitialSpeech,
      closeCamera,
      setStateIsVisibleSendCode,
      setStateInitTimerResendCodde,
      setStateTimerSecondsResendCode,
      onSetCompanyValues,
   } = appContext;

   return {
      videoRef,
      streamVideo,
      initStreamVideo,
      openModal,
      openAlert,
      isActiveAlert,
      errorAlert,
      messageAlert,
      severityAlert,
      timeOffAlert,
      isLoadingUploadVideo,
      enableSpeech,
      enableInitialSpeech,
      isVisibleSendCode,
      initTimerResendCode,
      timerSecondsResendCode,
      companyValues,
      onEnableSpeech,
      setInitStreamVideoState,
      setStreamVideoState,
      setOpenModalState,
      onSetOpenAlert,
      onSetIsLoadingUploadVideo,
      onEnableInitialSpeech,
      closeCamera,
      setStateIsVisibleSendCode,
      setStateInitTimerResendCodde,
      setStateTimerSecondsResendCode,
      onSetCompanyValues,
   }
}
export default useAppContext;
