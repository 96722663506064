import {useFieldArray, useForm, Controller} from "react-hook-form";
import useTestGeneratorViewModel from "../../view-models/admin/useTestGeneratorViewModel";
import {useEffect, useState} from "react";
import useAlertHook from "../../presentation/hooks/useAlertHook";
import {useParams, useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup"
import {
   ERROR_ONLY_NUMBERS,
   ERROR_ONLY_WHOLE_NUMBERS,
   MSG_ERROR,
   TEST_GENERATOR_ERRORS
} from "../../data/constant/Constants";
import {ROUTE_ADMIN_TEST_GENERATOR} from "../../data/constant/RouteConstants";
import useAuthViewModel from "../../view-models/useAuthViewModel";

const useTestGeneratorController = () => {
   const navigate = useNavigate();
   const [indexQuestion, setIndexQuestion] = useState(null);
   const [discartChanges, setDiscartChanges] = useState(false);
   const [questionHistoryIsVisible, setQuestionHistoryIsVisible] = useState(false);
   const [excRegenQuestion, setExcRegenQuestion] = useState(false);
   const [indexHistory, setIndexHistory] = useState(null);
   const [selfAssessmentValue, setSelfAssessmentValue] = useState(null);
   const [addingSelfAssessment, setAddingSelfAssessment] = useState([]);
   const [triggeredBy, setTriggeredBy] = useState(null);
   const [isResetTriggered, setIsResetTriggered] = useState(false);

   const configOptions = [
      {label: "Enable Professional Information Form", name: "professionalInfoRequired"},
      {label: "Enable About You section", name: "mandatoryTestsRequired"},
      {label: "Emotion Analysis by AI", name: "requiredTestEmotionalEvaluation"},
      {label: "Automated Scoring by AI (From 0 to 5)", name: "requiredTestIAEvaluation"},
      {label: "Include Self Assessment Questions", name: "selfAssessment"},
      {label: "AI-Powered questions", name: "questionWithIA"},
   ];

   const {skillId} = useParams();
   const {alert} = useAlertHook();
   const {
      skillData,
      setSkill,
      setRegenerateSkillId,
      setRegenSkillId,
      updateSkill,
      createSkill,
      regenerateQuestion,
      updateStatusSkill,
      data,
      dataUpdateSkill,
      dataRegenerate,
      isSuccess,
      isSuccessUpdate,
      isSuccessRegenerate,
      isSuccessFetchSkill,
      isError,
      errorSkillCreate,
      paramId,
      skill,
      setSkillId,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      isLoadingSkill,
      isFetchingSkill,
      isFetchingRegenerate,
   } = useTestGeneratorViewModel();

   const {token} = useAuthViewModel();

   const schema = yup
      .object().shape({
         skillName: yup.string().required(MSG_ERROR[0]).max(50, TEST_GENERATOR_ERRORS[0]),
         seniority: yup.string().required(MSG_ERROR[0]),
         language: yup.string(),
         name: yup.string().required(MSG_ERROR[0]).max(100, TEST_GENERATOR_ERRORS[1]),
         skillType: yup.string().required(MSG_ERROR[0]),
         notes: yup
            .string()
            .nullable()
            .default('')
            .max(500, TEST_GENERATOR_ERRORS[2])
            .test(
               "required-if-ai-generated",
               "Notes are required when AI questions are enabled",
               function (value) {
                  const {aiGeneratedQuestions} = this.parent;
                  if (aiGeneratedQuestions) {
                     return !!value;
                  }
                  return true;
               }
            ),

         extraQuestions: yup.lazy((value) => {
            return yup
               .array()
               .of(
                  yup.object().shape({
                     text: yup.string().required(MSG_ERROR[0]),
                     interactions: yup
                        .number()
                        .typeError(ERROR_ONLY_NUMBERS)
                        .required(MSG_ERROR[0])
                        .integer(ERROR_ONLY_WHOLE_NUMBERS)
                        .min(0, "Value must be at least 0")
                        .max(3, "Value cannot be greater than 3"),
                  })
               )
               .max(10, "You can only add up to 10 questions.")
               .default([]);
         }),

         questions: yup.lazy((value) => {
            return yup
               .array()
               .of(
                  yup.object().shape({
                     text: yup.string().required(MSG_ERROR[0]),
                     interactions: yup
                        .number()
                        .typeError(ERROR_ONLY_NUMBERS)
                        .required(MSG_ERROR[0])
                        .integer(ERROR_ONLY_WHOLE_NUMBERS)
                        .min(0, "Value must be at least 0")
                        .max(3, "Value cannot be greater than 3"),
                  })
               )
               .max(10, "You can only add up to 10 questions.")
               .default([]);
         }),
         active: yup.boolean().required(MSG_ERROR[0]),
         professionalInfoRequired: yup.boolean(),
         requiredTestEmotionalEvaluation: yup.boolean(),
         mandatoryTestsRequired: yup.boolean(),
         requiredTestIAEvaluation: yup.boolean(),
         selfAssessment: yup.boolean(),
         questionWithIA: yup.boolean(),
         questionsNumber: yup
            .number()
            .typeError(ERROR_ONLY_NUMBERS)
            .nullable()
            .transform((value, originalValue) => {
               return originalValue === "" ? null : value;
            })
            .test(
               "dynamic-default",
               "Value must be between 1 and 10",
               function (value) {
                  const { questionWithIA } = this.parent;
                  if (questionWithIA) {
                     return value === null || (value >= 1 && value <= 10);
                  }
                  return value === 0;
               }
            )
            .default(function () {
               return this.parent.questionWithIA ? null : 0;
            }),
         numberOfInteractionsPerQuestion: yup
            .number()
            .typeError(ERROR_ONLY_NUMBERS)
            .integer(ERROR_ONLY_WHOLE_NUMBERS)
            .min(0, "Value must be at least 0")
            .max(3, "Value cannot be greater than 3")
            .nullable()
            .transform((value, originalValue) => {
               return originalValue === "" ? null : value;
            }),
         selfAssessmentItems: yup
            .array()
            .of(
               yup.object().shape({
                  value: yup
                     .string()
                  // .required("Each self-assessment item must have a value."),
               })
            )
            .test(
               "required-if-self-assessment",
               "Self-assessment items are required when self-assessment is enabled.",
               function (value) {
                  const {selfAssessment} = this.parent;
                  if (selfAssessment) {
                     return Array.isArray(value) && value.length > 0;
                  }
                  return true;
               }
            )
            .default([])
      })

   const {
      watch,
      control,
      register,
      setValue,
      setError,
      getValues,
      handleSubmit,
      reset,
      formState: {errors},
      trigger,
   } = useForm({
      resolver: yupResolver(schema),
      mode: 'onChange',
      defaultValues: {
         selfAssessment: false,
         professionalInfoRequired: false,
         requiredTestEmotionalEvaluation: false,
         mandatoryTestsRequired: false,
         requiredTestIAEvaluation: false,
         questionWithIA: false,
         questionsNumber: 0,
      },
   });

   const {
      fields: fieldsQuestion,
      append: appendQuestion,
      remove: removeQuestion
   } = useFieldArray({
      control,
      name: "questions",
   })

   const {fields, append, remove} = useFieldArray({
      control,
      name: "extraQuestions",
      rules: {required: true},
   });

   const {
      fields: selfAssessmentFields,
      append: appendSelfAssessment,
      remove: removeSelfAssessment
   } = useFieldArray({
      control,
      name: "selfAssessmentItems",
      rules: {required: false},
   });

   const currentExtraQuestions = watch("extraQuestions");
   const isSelfAssessmentEnabled = watch("selfAssessment");
   const isAIGeneratedQuestionsEnabled = watch("questionWithIA");
   const currentQuestions = watch("questions");

   useEffect(() => {
      if (skillId) {
         setValue("skillName", skill?.data?.skillName);
         setValue("seniority", skill?.data?.seniority);
         setValue("name", skill?.data?.name);
         setValue("skillType", skill?.data?.skillType);
         setValue("notes", skill?.data?.notes);
         setValue("active", skill?.data?.active);
         setValue("professionalInfoRequired", skill?.data?.professionalInfoRequired);
         setValue("requiredTestEmotionalEvaluation", skill?.data?.requiredTestEmotionalEvaluation);
         setValue("mandatoryTestsRequired", skill?.data?.mandatoryTestsRequired);
         setValue("requiredTestIAEvaluation", skill?.data?.requiredTestIAEvaluation);
         setValue("questionWithIA", skill?.data?.questionWithIA);
         setValue("active", skill?.data?.active);

         setAddingSelfAssessment(skill?.data?.selfAssessmentItems);

         if (skill?.data?.selfAssessmentItems?.length > 0) {
            setValue("selfAssessment", true);
            skill.data.selfAssessmentItems.forEach((el) => {
               appendSelfAssessment({value: el});
            });
         } else {
            setValue("selfAssessment", false);
         }

         setValue("questionsNumber", skill?.data?.questions?.length || 0);
         setValue("numberOfInteractionsPerQuestion", skill?.data?.questions[0]?.interactions || 0)

         if (skill?.data?.questions?.length > 0) {
            setValue("questionWithIA", true);
            skill?.data?.questions?.forEach((el, index) => {
               appendQuestion({value: ''});
               setValue(`questions.${index}`, {
                  text: el.text,
                  interactions: el.interactions,
               });
            })
         } else {
            setValue("questionWithIA", false);
         }

         if (skill?.data?.extraQuestions) {
            skill?.data?.extraQuestions.forEach((el, index) => {
               append({value: ''});
               setValue(`extraQuestions.${index}`, {
                  text: el.text,
                  interactions: el.interactions,
               });
            });
         }
      }
   }, []);

   useEffect(() => {
      if (isResetTriggered) {
         const hasQuestions = Array.isArray(skill?.data?.questions) && skill?.data?.questions.length > 0;
         reset({
            skillName: skill?.data?.skillName,
            skillType: skill?.data?.skillType,
            seniority: skill?.data?.seniority,
            active: skill?.data?.active,
            name: skill?.data?.name || "",
            notes: skill?.data?.notes || "",
            professionalInfoRequired: skill?.data?.professionalInfoRequired || false,
            requiredTestEmotionalEvaluation: skill?.data?.requiredTestEmotionalEvaluation || false,
            mandatoryTestsRequired: skill?.data?.mandatoryTestsRequired || false,
            requiredTestIAEvaluation: skill?.data?.requiredTestIAEvaluation || false,
            selfAssessment: skill?.data?.selfAssessmentItems.length > 0 || false,
            questionsNumber: hasQuestions ? skill?.data?.questionsNumber || 1 : 0,
            numberOfInteractionsPerQuestion: skill?.data?.numberOfInteractionsPerQuestion || 0,
            questions: skill?.data?.questions || [],
            questionWithIA: Array.isArray(skill?.data?.questions) && skill?.data?.questions.length > 0,
            extraQuestions: skill?.data?.extraQuestions || []
         });
         if (skill?.data?.selfAssessmentItems?.length > 0) {
            skill.data.selfAssessmentItems.forEach((el) => {
               appendSelfAssessment({value: el});
            });
         }
         setIsResetTriggered(false);
      }
   }, [discartChanges, isResetTriggered]);

   useEffect(() => {
      if (skillId) {
         setSkillId(skillId);
         setRegenSkillId(skillId);
      } else {
         setSkill([]);
         setSkillId(null)
      }
   }, [skillId]);

   useEffect(() => {
      if (skillId && isSuccessFetchSkill) {
         setSkill(skill);
         // setInitValues();
      }
   }, [isSuccessFetchSkill, skill, skillId]);

   useEffect(() => {
      if (isSuccess) {
         alert(true, false, 'Skill success create');
         navigate(`${ROUTE_ADMIN_TEST_GENERATOR.TEST_GENERATOR}/${data?.data?._id}`);
      }
      if (isSuccessUpdate) {
         alert(true, false, 'Skill success update');
         setSkill(dataUpdateSkill);
      }

      if (isError) {
         const errorMsgNumberQuestion = errorSkillCreate.data.errors.some((el) =>
            ["questionsNumber"].includes(el.path)
         );

         const errorMsgNumberInteractions = errorSkillCreate.data.errors.some((el) =>
            ["numberOfInteractionsPerQuestion"].includes(el.path)
         );

         if (errorMsgNumberQuestion) {
            // alert(true, false, 'To create an AI interview, ' +
            //    'Number of Questions and Number of Interactions are required', "error");
            setError("questionsNumber", {
               type: "required",
               message: "required",
            });
         }

         if (errorMsgNumberInteractions) {
            setError("numberOfInteractionsPerQuestion", {
               type: "required",
               message: "required",
            });
         }
      }
   }, [isSuccess, isSuccessUpdate, isError]);

   useEffect(() => {
      if (skillId) setRegenerateSkillId(skillId);
   }, []);

   useEffect(() => {
      if (skillId && isSuccessRegenerate) {
         if (triggeredBy === "onRegenerateQuestion") {
            const currentQuestion = getValues(`questions.${indexQuestion}`);
            setValue(`questions.${indexQuestion}`, {
               ...currentQuestion,
               text: dataRegenerate?.questionRemaked[0],
            });
         }

         if (triggeredBy === "generateNewAIQuestion") {
            appendQuestion({value: ''})
            setValue(`questions.${currentQuestions.length}`, {
               text: dataRegenerate?.questionRemaked[0],
               interactions: '',
            });
         }
         setTriggeredBy(null);
      }
   }, [excRegenQuestion, indexQuestion, isSuccessRegenerate]);

   const onVisibleQuestionHistory = (index) => {
      setIndexHistory(index);
      setQuestionHistoryIsVisible(prevState => !prevState);
   }

   const onChangeStatusSkill = (status) => {
      console.log("status", status.target.value);
      // updateStatusSkill({
      //    id: paramId,
      //    status: status.target.value
      // });
   }

   const onDiscartChanges = () => {
      setDiscartChanges(prev => !prev);
      setIsResetTriggered(true);
   }

   const onRegenerateQuestion = (index) => {
      setIndexQuestion(index);
      regenerateQuestion({id: skillId})
      setExcRegenQuestion(prev => !prev)
      setTriggeredBy("onRegenerateQuestion");
   }

   const onDeleteExtraQuestionField = (index) => {
      remove(index);
   }

   const generateNewAIQuestion = () => {
      regenerateQuestion({id: skillId})
      setTriggeredBy("generateNewAIQuestion");
   }

   const handleChangeValueSelfAssessment = (e) => {
      setSelfAssessmentValue(e.target.value);
   }

   const handleAppendSelfAssessment = () => {
      if (!selfAssessmentValue) return
      const existSelfAssessment = addingSelfAssessment?.some(el => el === selfAssessmentValue);
      if (addingSelfAssessment.length < 15) {
         if (!existSelfAssessment) {
            appendSelfAssessment({
               value: selfAssessmentValue
            })
            setAddingSelfAssessment((prev) => [...prev, selfAssessmentValue])
            setSelfAssessmentValue('')
         }
      }
   }

   const handleAddQuestions = async () => {
      let quantity = currentExtraQuestions.length;

      if (
         currentExtraQuestions.length > 0 &&
         (!currentExtraQuestions[quantity - 1]?.text || isNaN(currentExtraQuestions[quantity - 1]?.interactions))
      ) {
         return;
      }

      if (currentExtraQuestions.length >= 10) {
         setError("extraQuestions", {
            type: "manual",
            message: "You can only add up to 10 questions.",
         });
         return
      }
      append({text: "", interactions: ""});
   }

   const onHandleSubmit = (data) => {
      const newExtraquestions = data.extraQuestions.map(({interactions, text}) => ({interactions, text}));
      const newSelfAssessment = data.selfAssessmentItems.map(item => item.value);

      const {
         scoreWithAI,
         selfAssessment,
         interactions,
         normalQuestions,
         ...skill
      } = data;
      skill.extraQuestions = newExtraquestions;
      skill.selfAssessmentItems = newSelfAssessment;

      if (!skill.questionWithIA) {
         if (skill.extraQuestions.length === 0) {
            alert(true, false, 'No questions found. Please register questions to create the interview.', "error");
            return;
         }
         skill.questionsNumber = 0;
         skill.numberOfInteractionsPerQuestion = 0;
      }

      if (!skillId) {
         delete skill.questions
         createSkill(skill);
      } else {
         skill.id = skillId;
         const cleanedQuestions = data?.questions?.map(({text, interactions}) => ({
            text: text.replace(/^\d+\.\s*/, ''),
            interactions
         }));
         skill.questions = cleanedQuestions;
         updateSkill(skill);
      }
   }

   const fetchPdf = async (id) => {
      try {
         const response = await fetch(`${process.env.REACT_APP_API_URL}/skill/test/pdf/${id}`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({reportType: 'detailed'})
         })

         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `test.pdf`);
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);

      } catch (e) {
         throw new Error('Network response was not ok');
      }
   }

   return {
      //Questions
      setValue,
      getValues,
      currentQuestions,
      removeQuestion,
      handleAddQuestions,
      currentExtraQuestions,
      triggeredBy,
      appendQuestion,
      fieldsQuestion,
      generateNewAIQuestion,
      // normalQuestionsAppeend,
      // normalQuestionsFields,
      // removeNormalQuestion,
      //
      isAIGeneratedQuestionsEnabled,
      isSelfAssessmentEnabled,
      //
      skillData,
      watch,
      control,
      fields,
      register,
      handleSubmit,
      append,
      createSkill,
      data,
      isSuccess,
      isError,
      skillId,
      errors,
      remove,
      indexQuestion,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      isLoadingSkill,
      isFetchingSkill,
      isFetchingRegenerate,
      questionHistoryIsVisible,
      setQuestionHistoryIsVisible,
      indexHistory,
      skill,
      //self assessment
      setSelfAssessmentValue,
      selfAssessmentValue,
      selfAssessmentFields,
      removeSelfAssessment,
      handleChangeValueSelfAssessment,
      handleAppendSelfAssessment,
      //
      onHandleSubmit,
      onDeleteExtraQuestionField,
      onRegenerateQuestion,
      onDiscartChanges,
      onChangeStatusSkill,
      onVisibleQuestionHistory,
      fetchPdf,
      // useForm
      Controller,
      configOptions,
   }
}
export default useTestGeneratorController;
