import {useDispatch, useSelector} from "react-redux";
import {
   useAuthMutation,
   useForgotPasswordMutation, useLazyFetchCompanyByIdQuery, useLazyFetchCompanysQuery,
   useRestorePasswordMutation
} from "../store/app/apiSlice/apiSlice";
import {setCredentials, logout, resetCredentials} from "../store/features/auth-slice";

const useAuthViewModel = () => {
   const dispatch = useDispatch();

   const {user, token} = useSelector(state => state.AuthReducer);

   const [auth, {data, isSuccess, isError, error: authError}] = useAuthMutation();

   const [sendEmailForgotPassword, {
      data: forgotPassData,
      isLoading: isLoadingForgotPassword,
      isSuccess: isSuccessForgotPassword,
      isError: isErrorForgotPassword,
      error: errorForgotPassword
   }] = useForgotPasswordMutation();

   const [restorePassword, {
      dataRestorePassword,
      isLoading: isLoadingRestorePassword,
      isSuccess: isSuccessRestorePassword,
      isError: isErrorRestorePassword,
      error: errorRestorePassword
   }] = useRestorePasswordMutation();

  const [triggerFetchCompany, {
        data: companyData,
        isFetching: isFetchingCompanyData,
        isSuccess: isSuccessCompaniesData,
     }] = useLazyFetchCompanyByIdQuery()

   return {
     //Fetch Company
      triggerFetchCompany,
      companyData,
      isFetchingCompanyData,
      isSuccessCompaniesData,
      //RestorePassword
      restorePassword,
      dataRestorePassword,
      isLoadingRestorePassword,
      isSuccessRestorePassword,
      isErrorRestorePassword,
      errorRestorePassword,
      //Forgot password
      sendEmailForgotPassword,
      forgotPassData,
      isSuccessForgotPassword,
      isErrorForgotPassword,
      errorForgotPassword,
      isLoadingForgotPassword,
      //auth
      token,
      user,
      auth,
      data,
      isSuccess,
      isError,
      authError,
      // fn
      logout: () => dispatch(logout()),
      setCredentials: (credentials) => dispatch(setCredentials(credentials)),
      resetCredentials: () => dispatch(resetCredentials()),
   }
}
export default useAuthViewModel;
