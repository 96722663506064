import {useNavigate} from "react-router-dom";
import useAppContext from "../../presentation/hooks/useAppContext";
import useTestGeneratorViewModel from "../../view-models/admin/useTestGeneratorViewModel";
import {useEffect, useState} from "react";
import useAlertHook from "../../presentation/hooks/useAlertHook";
import {ROUTE_ADMIN_CANDIDATE, ROUTE_ADMIN_TEST_GENERATOR, ROUTE_ENDPOINTS} from "../../data/constant/RouteConstants";
import useCandidateViewModel from "../../view-models/admin/useCandidateViewModel";

const useTestListController = () => {
   const [resultSkills, setResultSkills] = useState([]);
   const [totalPages, setTotalPages] = useState(0);
   const [userFoundData, setUserFoundData] = useState([]);
   const [onSendCandidates, setOnSendCandidates] = useState(false);
   const [openSnackbar, setOpenSnackbar] = useState(false);
   const [addCandidateSuccess, setAddCandidateSuccess] = useState(false);

   let navigation = useNavigate();
   const {setOpenModalState} = useAppContext();
   const {alert} = useAlertHook();

   const {
      idSkill,
      addCandidate,
      candidates,
      skills,
      isFetchingSkills,
      setPage,
      page,
      limit,
      userFound,
      isSuccessAddCandiate,
      isSuccessFoundUser,
      isErrorFoundUser,
      isErrorAddCandidate,
      isLoadingAddCandidate,
      isFetchingFoundUser,
      isErrorFetchSkills,
      refetchSkill,
      refetchSkills,
      setSkillId,
      setCandidates,
      removeCandidate,
      resetState,
      errorsAddCandidates,
      setSearchCriteria,
      setCandidateParam,
   } = useTestGeneratorViewModel();

   const { setSearchCriteria: setCandidateCriteria} = useCandidateViewModel();

   useEffect(() => {
      if (isSuccessFoundUser) setUserFoundData(userFound);
      if (isErrorFoundUser) setUserFoundData([]);
   }, [isSuccessFoundUser, isErrorFoundUser, userFound]);

   useEffect(() => {
      refetchSkills();
   }, [page]);

   useEffect(() => {
      if (!skills?.data?.length) setSearchCriteria('');
      setResultSkills(skills?.data);
      setTotalPages(skills?.pagination?.totalPages);
   }, [skills, resultSkills, totalPages, page]);

   const navigateToEditSkill = (id) => {
      navigation(`${ROUTE_ADMIN_TEST_GENERATOR.TEST_GENERATOR}/${id}`);
      refetchSkill();
   }

   const onUserSearch = (query) => {
      setCandidateParam(query)
   }

   const onAddCandidate = (data) => {
      setCandidates(data);
   };

   const openAddCandidateModal = (_id, stateModal) => {
      setSkillId(_id);
      setOpenModalState(stateModal);
   }

   const onCloseCandidateModal = () => {
      resetState();
      setOpenModalState(false);
      setOpenSnackbar(false);
   }

   const onFilterData = (query) => {
      if (page > 1) setPage(1)
      setSearchCriteria(query);
   }

   const onRemoveCandidate = (candidate) => removeCandidate(candidate?._id);

   const onSendSkillCandidate = async () => {
      if (candidates.length > 0) {
         let candidatesArray = candidates.map((el) => el._id);
         let candidatesToSend = {
            _id: idSkill,
            userIds: candidatesArray
         };

         try {
            await addCandidate(candidatesToSend).unwrap();
            setCandidateCriteria("");
            alert(true, false, "The email has been sent successfully", "success");
            setTimeout(() => {
               resetState();
               setOpenModalState(false);
            }, 2000);
         } catch (error) {
            console.error("Add Candidate Error:", error);
            alert(true, false, errorsAddCandidates?.data?.message || 'One or more users are already added to the test candidates', 'error');
         }
      }
   };

   const handleShareTest = () => {
      const url = `${window.location.origin}${ROUTE_ENDPOINTS.TEST_LANDING_SKILL.replace(':skillId', idSkill)}`;
      navigator.clipboard.writeText(url)
         .then(() => {
            setOpenSnackbar(true);
         })
         .catch((err) => {
            console.error('Failed to copy: ', err);
         });
   }

   return {
      navigation,
      skills,
      isFetchingSkills,
      setPage,
      page,
      limit,
      resultSkills,
      totalPages,
      userFound,
      userFoundData,
      candidates,
      isLoadingAddCandidate,
      isFetchingFoundUser,
      refetchSkills,
      idSkill,
      openSnackbar,
      setOpenSnackbar,
      isErrorFetchSkills,
      setOpenModalState,
      onFilterData,
      onUserSearch,
      onAddCandidate,
      openAddCandidateModal,
      onCloseCandidateModal,
      onSendSkillCandidate,
      onRemoveCandidate,
      navigateToEditSkill,
      handleShareTest
   }
}
export default useTestListController;
